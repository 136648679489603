import { mdiHeart } from "@mdi/js";
import Icon from "@mdi/react";

function Footer(): React.JSX.Element {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p>
                    Made with 
                    <span className="icon-text">
                        <span className="icon">
                            <Icon path={mdiHeart} size={0.65} />
                        </span>
                    </span> 
                    by&nbsp;
                    <a href="https://goodknightgame.com">
                        Team Good Knight
                    </a> and&nbsp; 
                    <a href="https://instilledbee.net">
                        InstilledBee's Studio
                    </a>
                </p>
                <p className="is-size-7">
                    Copyright {new Date().getFullYear()} Watch PHVTubers
                </p>
            </div>
        </footer>
    )
}

export default Footer;