import { mdiFacebook, mdiTwitch, mdiWeb, mdiYoutube } from "@mdi/js";
import Icon from "@mdi/react";
import * as UrlHelper from '../../helpers/UrlHelper';
import './SocialLink.css';
import React from "react";

/**
 * Automatically styled links based on the website root domain
 */
export function SocialLink({ children, href } : {
    children: string,
    href: string
}): React.JSX.Element {
    let website = UrlHelper.extractRootDomain(href);

    switch (website) {
        case "youtube.com":
            return (
                <a href={href} target="_blank" rel="noreferrer" className="youtube-link">
                    <span className="icon-text">
                        <span className="icon social-icon">
                            <Icon path={mdiYoutube} size={1} />
                        </span>
                        <span>{children}</span>
                    </span>
                </a>
            );

        case "twitch.tv":
            return (
                <a href={href} target="_blank" rel="noreferrer" className="twitch-link">
                    <span className="icon-text">
                        <span className="icon social-icon">
                            <Icon path={mdiTwitch} size={1} />
                        </span>
                        <span>{children}</span>
                    </span>
                </a>
            );

        case "facebook.com":
        case "fb.gg":
            return (
                <a href={href} target="_blank" rel="noreferrer" className="facebook-link">
                    <span className="icon-text">
                        <span className="icon social-icon">
                            <Icon path={mdiFacebook} size={1} />
                        </span>
                        <span>{children}</span>
                    </span>
                </a>
            );

        default:
            return (
                <a href={href} target="_blank" rel="noreferrer">
                    <span className="icon-text">
                        <span className="icon social-icon">
                            <Icon path={mdiWeb} size={1} />
                        </span>
                        <span>{children}</span>
                    </span>
                </a>
            );
    }
}