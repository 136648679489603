export function fetch(): FeaturedVTuber[] {
    return [
        {
            id: 1,
            thumbnail: "https://placekitten.com/80/80",
            handle: "InstilledBee",
            channelUrl: "https://twitch.tv/instilledbee",
            name: "InstilledBee"
        },
        {
            id: 2,
            thumbnail: "https://placekitten.com/g/80/80",
            handle: "@GoodKnightGame",
            channelUrl: "https://youtube.com/@GoodKnightGame",
            name: "Alex Valdez"
        },
        {
            id: 3,
            thumbnail: "https://placekitten.com/79/79",
            handle: "VTuberOne",
            channelUrl: "https://facebook.com/ibeestudio",
            name: "VTuber 1"
        },
        {
            id: 4,
            thumbnail: "https://placekitten.com/g/79/79",
            handle: "OkayAlex",
            channelUrl: "https:/okayalex.com",
            name: "VTuber 2"
        }
    ]
}

export interface FeaturedVTuber {
    id: number,
    thumbnail: string,
    channelUrl: string,
    handle: string,
    name: string
}