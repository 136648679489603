import SocialLink from '../../common/SocialLink';
import { RecentVideo } from './services/RecentVideosService';

function RecentVideos({ videos }: {
    videos: RecentVideo[]
}) : React.JSX.Element {
    let dateFormat = new Intl.DateTimeFormat("en-us");

    return (
        <section className="section">
            <h1 className="title">Recent Videos</h1>
            <table className="table is-fullwidth is-striped">
                <thead>
                    <tr>
                        <th>
                            VTuber
                        </th>
                        <th>
                            Video
                        </th>
                        <th>
                            Upload Date
                        </th>
                    </tr>
                </thead>
                <tbody>{
                    videos.map((v) => {
                        return (
                            <tr key={v.id}>
                                <td>
                                    <div className="is-flex is-align-items-center">
                                        <img src={v.avatarUrl} className="mr-3"></img>
                                        <p className="has-text-weight-medium">{v.channelName}</p>
                                    </div>
                                </td>
                                <td>
                                    <SocialLink href={v.videoUrl}>{v.title}</SocialLink>
                                </td>
                                <td>
                                    {dateFormat.format(v.uploadDate)}
                                </td>
                            </tr>
                        )
                    })
                }</tbody>
            </table>
        </section>
    );
}

export default RecentVideos;