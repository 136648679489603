import VTuberCardSmall from '../../common/VTuberCardSmall';
import './Home.css';
import RecentVideos from './RecentVideos';
import * as RecentVideosService from './services/RecentVideosService';
import * as FeaturedVTubersService from './services/FeaturedVTubersService';

export function Home() {
    let recentVideos = RecentVideosService.fetch();
    let featuredVTubers = FeaturedVTubersService.fetch();

    return (
        <>
        <section className="section">
            <p>Watch <strong>PH VTubers</strong> live right now, on YouTube, Twitch, Facebook Gaming, or other platforms.</p>
            <p>Browse the PH VTuber directory to get to know your favorite virtual streamers.</p>
        </section>

        <section className="section is-link is-flex featured">
            <img src="https://placekitten.com/300/300" className="is-pulled-left"></img>
            <div className="mx-4">
                <p className="title">Featured PH VTuber</p>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
        </section>

        <section className="section">
            <h1 className="title mb-5">Live PH VTubers</h1>
            <nav className="level">
                {
                    featuredVTubers.map((vt) => {
                        return (
                            <VTuberCardSmall 
                                key={vt.id}
                                name={vt.name}
                                handle={vt.handle}
                                thumbnail={vt.thumbnail}
                                channelUrl={vt.channelUrl} />
                        );
                    })
                }
            </nav>
        </section>

        <RecentVideos videos={RecentVideosService.fetch()} />
        </>
    );
}