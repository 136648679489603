import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';

function Navbar(): React.JSX.Element {
    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <strong>Watch PHVTubers</strong>
            </a>
          
            <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" 
              data-target="mainNav" onClick={clickNav}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
    
          <div id="mainNav" className="navbar-menu">
            <div className="navbar-start">
              <a className="navbar-item" href="/">
                Home
              </a>
              
              <a className="navbar-item" href="/">
                VTubers
              </a>

              <div className="navbar-item has-dropdown" onClick={clickDropdown}>
                <a className="navbar-link">Streams</a>

                <div className="navbar-dropdown">
                  <a className="navbar-item">
                    Upcoming
                  </a>
                  <a className="navbar-item">
                    Recent
                  </a>
                </div>
              </div>
    
              <a className="navbar-item" href="/">
                About
              </a>
            </div>
    
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="field">
                  <p className="control has-icons-right">
                    <input className="input" type="text" placeholder="Search"></input>
                    <span className="icon is-small is-right">
                      <Icon path={mdiMagnify} size={1} />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </nav>
      );
}

function clickNav(e: React.MouseEvent<HTMLAnchorElement>) {
  // Get the target from the "data-target" attribute
  const navTarget = e.currentTarget.dataset.target;

  if (navTarget) {
    const $target = document.getElementById(navTarget);

    // Toggle the "is-active" className on both the "navbar-burger" and the "navbar-menu"
    e.currentTarget.classList.toggle('is-active');
    $target?.classList.toggle('is-active');
  }
}

function clickDropdown(e: React.MouseEvent<HTMLDivElement>) {
  e.currentTarget.classList.toggle('is-active');
}

export default Navbar;