import React from 'react';
import SocialLink from '../SocialLink';

/** VTuber name and 1 social link with icon.
 * 
 * Use this component inside a Bulma `level` div */
export function VTuberCardSmall(props: {
    name: string,
    thumbnail: string,
    channelUrl: string,
    handle: string
}): React.JSX.Element {
    return (
        <div className="level-item is-flex is-justify-content-flex-start">
            <img src={props.thumbnail} className="is-pulled-left circle" width="60"></img>
            <div className="is-flex-direction-column">
            <p className="has-text-weight-medium is-size-5 ml-2">
                {props.name}
            </p>
            <p className="ml-1">{(props.channelUrl && props.handle) ? (
                <SocialLink href={props.channelUrl}>{props.handle}</SocialLink>
            ) : ""}</p>
            </div>
        </div>
    );
}