export function fetch(): RecentVideo[] {
    return [
        {
            id: 0,
            channelName: "InstilledBee",
            avatarUrl: "https://placekitten.com/80/80",
            title: "Video 1 from Youtube.com",
            videoUrl: "https://www.youtube.com",
            uploadDate: new Date()
        },
        {
            id: 1,
            channelName: "InstilledBee",
            avatarUrl: "https://placekitten.com/80/80",
            title: "Video 2 from Youtube.com",
            videoUrl: "https://www.youtube.com",
            uploadDate: new Date()
        },
        {
            id: 2,
            channelName: "InstilledBee",
            avatarUrl: "https://placekitten.com/80/80",
            title: "Video 1 from Twitch.tv",
            videoUrl: "https://www.twitch.tv/instilledbee",
            uploadDate: new Date()
        },
        {
            id: 3,
            channelName: "InstilledBee",
            avatarUrl: "https://placekitten.com/80/80",
            title: "Video 1 from Facebook",
            videoUrl: "https://fb.gg",
            uploadDate: new Date()
        }
    ]
}

export interface RecentVideo {
    id: number;
    avatarUrl: string;
    channelName: string;
    title: string;
    videoUrl: string;
    uploadDate: Date;
}