import './App.css';
import Home from './routes/Home';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';

function App() {
  return (
    <>
      <div className="container">
        <Navbar />
        <Home />
      </div>
      <Footer />
    </>
  );
}

export default App;
